import React, { memo, useEffect } from "react";
import { Typography } from "antd";
import AlectifyText from "static/texts.json";
import { PlusOutlined } from "@ant-design/icons";
import AlectifyTable from "components/shared/table";
import AlectifyButton from "components/shared/button";
import { IncidentReportTableProps } from "pages/incident-report/Incident.interface";
import IncidentReportsColumns from "./effects/useIncidentReportsColumn";
import { IRootState } from "redux/rootReducer";
import { useSelector } from "react-redux";
import "./IncidentReport.scss";

const IncidentReportTable: React.FC<IncidentReportTableProps> = ({
  onEdit,
  onDeleteRecord,
  onDeleteDocuments,
  onStatusUpdate,
  allReports,
  openCreateIncident,
  fetchAllIncidentReports,
  handleIncidentDetail,
  selectedRowId,
  setSelectedRowId,
}) => {
  const { activeMasterProject } = useSelector(
    ({ common }: IRootState) => common,
  );
  const columns = IncidentReportsColumns({
    onEdit,
    onDeleteRecord,
    onDeleteDocuments,
    onStatusUpdate,
    handleIncidentDetail,
  });

  useEffect(() => {
    setSelectedRowId(null);
  }, [activeMasterProject]);

  const handleRowClick = (record: any) => {
    setSelectedRowId(record.id);
  };

  const rowClassName = (record: any) => {
    return record.id === selectedRowId
      ? "incident-table-rowClassName-selected"
      : "incident-table-rowClassName";
  };
  return (
    <>
      <Typography.Title level={4} className="mt-10">
        {AlectifyText.INCIDENT_REPORTS}
      </Typography.Title>
      <AlectifyTable
        showSearch
        scroll={{ x: 1320, y: "calc(100vh - 310px)" }}
        columns={columns}
        searchPlaceholder="Search Site, Report Id, Title"
        dataSource={allReports?.data}
        total={allReports?.meta?.totalItems}
        displayRowClass={rowClassName}
        onDataFetch={fetchAllIncidentReports}
        columnFilterAsString
        loading={allReports.fetching}
        customSearchKey="search"
        extraButton={
          <AlectifyButton
            text={AlectifyText.CREATE_REPORT}
            type="primary"
            icon={<PlusOutlined />}
            onClick={openCreateIncident}
          />
        }
        onRowClick={handleRowClick}
      />
    </>
  );
};

export default memo(IncidentReportTable);
