import { LIGHT_BLUE_COLOR } from "components/shared/phone-number/constants";

export const siderStyle: React.CSSProperties = {
  backgroundColor: "#fff",
  position: "sticky",
  top: 0,
  height: "100vh",
  paddingLeft: "1px",
  paddingRight: "1px",
  background: "#E7EEFE",
  overflowY: "auto",
  overflowX: "hidden",
};

export const menuStyles: React.CSSProperties = {
  backgroundColor: "transparent",
  transition: "unset",
  width: "100%",
};

export const listStyles: React.CSSProperties = {
  paddingLeft: "10px",
};

export const listCenteredStyles: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const menuStylesSecond: React.CSSProperties = {
  backgroundColor: "transparent",
};

export const headerStyle: React.CSSProperties = {
  textAlign: "center",
  height: 48,
  backgroundColor: "#fff",
  borderBottom: "1px solid #DAE5F3",
  display: "flex",
  alignItems: "center",
  padding: "10px",
  position: "sticky",
  top: 0,
  zIndex: 9,
  width: "100%",
};

export const mainLayoutContainerStyles: React.CSSProperties = {
  display: "flex",
  height: "100vh",
  margin: "0",
  boxSizing: "border-box",
};

export const contentStyles: React.CSSProperties = {
  flex: "1",
  overflow: "auto",
  padding: "10px 10px 3px 20px",
  background: "rgb(255, 255, 255)",
  boxSizing: "border-box",
  overflowX: "hidden",
};

export const footerStyle: React.CSSProperties = {
  backgroundColor: "#eef0f2",
  zIndex: "8",
  width: "100%",
};

export const headerDividerStyles: React.CSSProperties = {
  height: 35,
  backgroundColor: LIGHT_BLUE_COLOR,
  marginTop: 8,
  marginLeft: 17,
};

export const siteFilterStyle: React.CSSProperties = {
  width: 500,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};
