import { memo } from "react";
import { Avatar, Badge, Tooltip } from "antd";
import { getFullUserName, getUserImage, isExternalUser } from "utils/helpers";
import { IAvatarGroupProps } from "./AvatarGroup.interface";

import "./AvatarGroup.scss";
import { ArrowRightOutlined } from "@ant-design/icons";
import { USER_ACCESS_TYPES } from "enums";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import AlectifyModal from "../modal";
import { MODAL_NAMES } from "constants/modal.constants";
import ViewImage from "components/shared/view-image/ViewImage";

const AvatarGroup: React.FC<IAvatarGroupProps> = (props: IAvatarGroupProps) => {
  if (!props.users?.length) {
    return <>-</>;
  }

  return (
    <Avatar.Group maxCount={props.maxCount} size={props.size || "default"}>
      {props.users.map((user) => (
        <Tooltip
          title={`${getFullUserName(user)} (${
            user?.user_type && USER_ACCESS_TYPES[user?.user_type]
          })`}
          key={user?.id || user?.email}
        >
          <Badge
            count={
              isExternalUser(user) ? (
                <ArrowRightOutlined className="external-user-arrow-icon" />
              ) : (
                0
              )
            }
            offset={[-6, 28]}
          >
            <Avatar
              src={getUserImage(user)}
              className={`${props.showImage && "cursor-pointer"} ${
                isExternalUser(user) ? "alectify-avatar-external-user" : ""
              }`}
              onClick={() => {
                props.showImage &&
                  ModalServiceInstance.open(AlectifyModal, {
                    onCancel: () => {
                      ModalServiceInstance.close(AlectifyModal, {
                        name: MODAL_NAMES.TASK_TABLE_IMAGE_MODAL,
                      });
                    },
                    name: MODAL_NAMES.TASK_TABLE_IMAGE_MODAL,
                    title: "Preview",
                    footer: null,
                    children: <ViewImage imageUrl={getUserImage(user)} />,
                  });
              }}
            />
          </Badge>
        </Tooltip>
      ))}
    </Avatar.Group>
  );
};

AvatarGroup.defaultProps = {
  users: [],
  maxCount: 5,
  size: "default",
  showImage: false,
};

export default memo(AvatarGroup);
