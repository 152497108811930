import React, { useState, useRef, useEffect, useCallback } from "react";
import { UpCircleFilled } from "@ant-design/icons";
import { IDraggableButtonProps } from "./DraggableButton.interface";
import "./DraggableButton.scss";

const DraggableButton: React.FC<IDraggableButtonProps> = (props) => {
  const [position, setPosition] = useState<number>(0);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const [startX, setStartX] = useState<number>(0);
  const [startY, setStartY] = useState<number>(0);
  const buttonRef = useRef<HTMLDivElement>(null);

  const buttonHeight = 200; // Height of the button
  const MIN_Y = 0;
  const MAX_Y = window.innerHeight - buttonHeight;
  const CLICK_THRESHOLD = 5; // Threshold for click vs. drag

  // Center the button vertically on initial mount
  useEffect(() => {
    const initialPosition = (window.innerHeight - buttonHeight) / 2;
    setPosition(initialPosition);
  }, [buttonHeight]);

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!isDragging || !buttonRef.current) return;

      const newY = e.clientY - offset;
      const clampedY = Math.max(MIN_Y, Math.min(newY, MAX_Y));
      setPosition(clampedY);
    },
    [isDragging, offset, MIN_Y, MAX_Y],
  );

  const handleMouseUp = useCallback(
    (e: MouseEvent) => {
      setIsDragging(false);

      // Check if the mouse moved significantly to differentiate between a click and a drag
      const deltaX = Math.abs(startX - e.clientX);
      const deltaY = Math.abs(startY - e.clientY);

      if (deltaX < CLICK_THRESHOLD && deltaY < CLICK_THRESHOLD) {
        // Trigger onClick if the movement was below the threshold
        props.onShowComments(true);
      }
    },
    [isDragging, startX, startY],
  );

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
      return () => {
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);
      };
    }
  }, [isDragging, handleMouseMove, handleMouseUp]);

  useEffect(() => {
    const handleResize = () => {
      setPosition((prevPosition) => {
        const newMaxY = window.innerHeight - buttonHeight;
        return Math.min(prevPosition, newMaxY);
      });
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [buttonHeight]);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsDragging(true);
    if (buttonRef.current) {
      setOffset(e.clientY - buttonRef.current.getBoundingClientRect().top);
      setStartX(e.clientX); // Track the starting position
      setStartY(e.clientY);
    }
  };

  return (
    <div
      ref={buttonRef}
      className={`draggable-button`}
      style={{ top: `${position}px`, right: "0px" }}
      onMouseDown={handleMouseDown}
    >
      <span className="button-text">
        <UpCircleFilled style={{ fontSize: 18 }} />
      </span>
    </div>
  );
};

export default DraggableButton;
