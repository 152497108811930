import {
  IResetPasswordPayload,
  IResetPasswordResponse,
} from "pages/reset-password/ResetPassword.interface";
import { http } from "utils/client";
import {
  FCM_TOKEN,
  FORGET_PASSWORD,
  LOGIN_URL,
  REQ_RESET_PASSWORD,
  TOKEN_REFRESH,
} from "./auth.endpoints";
import {
  ILoginPayload,
  ILoginResponse,
  IRefreshTokenRequestBody,
} from "./auth.interface";
import {
  IForgetPasswordPayload,
  IForgetPasswordResponse,
} from "pages/forget-password/ForgetPassword.interface";

export const loginRequest = async (
  body: ILoginPayload,
): Promise<ILoginResponse> => {
  try {
    const response = await http.post(LOGIN_URL, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const ForgetpasswordRequest = async (
  body: IForgetPasswordPayload,
): Promise<IForgetPasswordResponse> => {
  try {
    const response = await http.post(FORGET_PASSWORD, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const requestResetPassword = async (
  body: IResetPasswordPayload,
): Promise<IResetPasswordResponse> => {
  try {
    const response = await http.patch(REQ_RESET_PASSWORD, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verifyResetPasswordToken = async (
  uidb64: string,
  token: string,
): Promise<IResetPasswordResponse> => {
  try {
    const response = await http.get(
      `${REQ_RESET_PASSWORD}${uidb64}/${token}/`,
      {},
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Refreshes the authentication token by sending an HTTP POST request.
 * @param body - The request body for refresh token.
 * @returns Token from the response if the request is successful.
 * @throws An error if an error occurs during the request.
 */
export const fetchRefreshToken = async (body: IRefreshTokenRequestBody) => {
  try {
    const response = await http.post(TOKEN_REFRESH, body);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const saveFirebaseToken = async (token: string) => {
  try {
    const response = await http.post(FCM_TOKEN, {
      token,
      device_type: "Web",
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const deleteFirebaseToken = async (token: string) => {
  try {
    const response = await http.delete(FCM_TOKEN, {
      token,
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
};

// export const register = async (body) => {
//     try {
//         const response = await http.post('/register/', body);
//         return response.data;
//     } catch (error) {
//         throw error;
//     }
// };

// export const registeredUsers = async () => {
//     try {
//         const response = await http.get('/users/');
//         return response.data;
//     } catch (error) {
//         throw error;
//     }
// };

// export const updateUser = async (data) => {
//     try {
//         const response = await fileHttp.patch('/user/', data);
//         return response.data;
//     } catch (error) {
//         throw error;
//     }
// };

// export const requestResetPassword = async (body) => {
//     try {
//         const response = await http.post('/request-reset-password/', body);
//         return response.data;
//     } catch (error) {
//         throw error;
//     }
// };

// export const verifyResetPasswordToken = async (uidb64, token) => {
//     try {
//         const response = await http.get(`/reset-password/${uidb64}/${token}/`);
//         return response.data;
//     } catch (error) {
//         throw error;
//     }
// };

// export const resetPassword = async (body) => {
//     try {
//         const response = await http.patch('/reset-password/', body);
//         return response.data;
//     } catch (error) {
//         throw error;
//     }
// };

// export const validateEmail = async (body) => {
//     try {
//         const response = await http.post('/user/validate-email/', body);
//         return response.data;
//     } catch (error) {
//         throw error;
//     }
// };

// export const fetchContacts = async () => {
//     try {
//         const response = await http.get('/user/contacts/');
//         return response.data;
//     } catch (error) {
//         throw error;
//     }
// };

// export const fetchUserDetails = async () => {
//     try {
//         const response = await http.get('/user/');
//         return response.data;
//     } catch (error) {
//         throw error;
//     }
// };

// export const fetchUsersWithBranches = async () => {
//     try {
//         const response = await http.get('/admin/users/');
//         return response.data;
//     } catch (error) {
//         throw error;
//     }
// };

// export const userSyncWithPackages = async (projectId, body) => {
//     try {
//         const response = await http.post(`/projects/${projectId}/sync-user/`, body);
//         return response.data;
//     } catch (error) {
//         throw error;
//     }
// };
