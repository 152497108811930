import { Space, Tooltip } from "antd";
import { get, isEmpty, truncate } from "lodash";
import { memo, useEffect, useState } from "react";
import AlectifyText from "static/texts.json";
import { IPmDetailProps } from "./Pmdetail.interface";
import {
  AlectifyDetailCard,
  AlectifyDetailCardImage,
  AlectifyDetailCardTitle,
} from "../detail-card";
import { NO_IMAGE_AVATAR } from "../assets-table/AssetsTable.constants";
import { AssetPackageTag, AssetTag, GenericTag } from "../tags";
import { IPmExternal, PM_TYPES } from "redux/components/pm-external";
import { fetchInternalPMDetails } from "services/pm-internal/pm-internal.service";
import { IPmInternal } from "redux/components/pm-internal";
import { fetchExternalPMDetails } from "services/pm-external/pm-external.service";
import { IUser } from "redux/components/Auth";
import { useDispatch } from "react-redux";
import AlectifyButton from "../button";
import { ROUTES } from "routes/Routes.constants";
import { useNavigate } from "react-router-dom";
import DrawerServiceInstance from "../CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import { actions } from "redux/components/notifications";
import AlectifyDescription from "../detailed-view/AlectifyDescriptionView";
import { DescriptionViewHeight } from "../detailed-view/AlectifyDescriptionView.interface";
import "./PmDetails.scss";
import PmInfoCard from "./PmInformationCard";
import { ITask } from "redux/components/tasks";
import { MaintenanceCategoriesEnum } from "enums";
import AlectifyChip from "../chips/AlectifyChip";

const PmDetail: React.FC<IPmDetailProps> = (props: IPmDetailProps) => {
  const { pmId } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState<boolean>(false);
  const [pmInternal, setPmInternal] = useState<IPmInternal | null>(null);
  const [pmExternal, setPmExternal] = useState<ITask | null>(null);
  const [teamMembers, setTeamMembers] = useState<IUser[]>([]);
  const assetPackage = get(
    props.pmType === PM_TYPES.PM_EXTERNAL || props.pmType === PM_TYPES.TASK
      ? pmExternal
      : pmInternal,
    "area",
    null,
  );
  const asset = get(
    props.pmType === PM_TYPES.PM_EXTERNAL || props.pmType === PM_TYPES.TASK
      ? pmExternal
      : pmInternal,
    "asset",
    null,
  );
  /*   const { activeSubProject, activeMasterProject } = useSelector(
    ({ common }: IRootState) => common,
  );
 */
  const getPmInternalDetails = async (id: string) => {
    setLoader(true);
    try {
      const response = await fetchInternalPMDetails(id);
      if (response.status) {
        setPmInternal(response?.data);
        switchTeamMemebersCall(response?.data as IPmExternal);
      }
    } catch (ex) {
      setLoader(false);
      console.log(ex);
    }
  };
  const getPmExternalDetails = async (id: string) => {
    setLoader(true);
    try {
      const response = await fetchExternalPMDetails(id);
      if (response.status) {
        setPmExternal(response?.data);
        switchTeamMemebersCall(response?.data);
      }
    } catch (ex) {
      setLoader(false);
      console.log(ex);
    }
  };

  useEffect(() => {
    if (pmId && props.pmType) {
      if (props.pmType === PM_TYPES.PM_INTERNAL) getPmInternalDetails(pmId);
      if (
        props.pmType === PM_TYPES.PM_EXTERNAL ||
        props.pmType === PM_TYPES.TASK
      )
        getPmExternalDetails(pmId);
    }
  }, [pmId, props?.pmType]);

  const switchTeamMemebersCall = (response: IPmExternal) => {
    if (isEmpty(response?.team)) {
      const teamMembers =
        response?.teamMembers?.map((member: any) => member.user) ?? [];
      setTeamMembers(teamMembers);
    } else {
      const responseTeam = Array.isArray(response.team?.projectTeamMembers)
        ? response.team.projectTeamMembers.flatMap((member: any) => member.user)
        : [];
      setTeamMembers(responseTeam);
    }
    setLoader(false);
  };

  /*   const getSubProjectTeamMembers = async (subProjectId: string) => {
    try {
      const response = await fetchSubProjectDetail(subProjectId, "users");
      setTeamMembers((response?.data as any) || []);
      setLoader(false);
    } catch (ex) {
      console.log(ex);
      setLoader(false);
    }
  };
 */
  /*  const getAssetTeamMembers = async (pmId: string, subProjectId: string) => {
    try {
      const response = await fetchAssetDetail(pmId, subProjectId);
      setTeamMembers(response?.team_members);
      setLoader(false);
    } catch (ex) {
      console.log(ex);
      setLoader(false);
    }
  }; */
  /* 
  const getAreaTeamMembers = async (pmId: string, subProjectId: string) => {
    try {
      const response = await fetchAssetPackageTeamMembers(pmId, subProjectId);
      setTeamMembers(response?.data);
      setLoader(false);
    } catch (ex) {
      console.log(ex);
      setLoader(false);
    }
  }; */

  const goToPmDetail = () => {
    const pmId = pmExternal?.id || pmInternal?.id;
    const masterProjectId = pmExternal?.project?.id || pmInternal?.project?.id;
    const subProjectId =
      pmExternal?.subProject?.id || pmInternal?.subProject?.id;
    const url = `${ROUTES.MY_ITEMS}/pm/${masterProjectId}/${subProjectId}/${pmId}?pmType=${props.pmType}`;
    dispatch(actions.toggleNotificationDropdown(false));
    navigate(url);
    DrawerServiceInstance.close(AlectifyDrawer, {
      name: [
        DRAWER_CONSTANTS.PM_INTERNAL_DETAIL_DRAWER,
        DRAWER_CONSTANTS.PM_EXTERNAL_DETAIL_DRAWER,
      ],
    });
  };

  return (
    <>
      <AlectifyDetailCard loading={loader} className="pb-10">
        <>
          <AlectifyDetailCardImage
            showImage={!isEmpty(pmExternal?.imageUrl)}
            src={pmExternal?.imageUrl || NO_IMAGE_AVATAR}
          />
          <AlectifyDetailCardTitle
            title={
              <Space size={"small"}>
                <Tooltip title={pmExternal?.workTitle}>
                  {truncate(pmExternal?.workTitle, {
                    length: 30,
                    omission: "...",
                  })}{" "}
                </Tooltip>
                <span>
                  {pmExternal?.taskCategory && (
                    <Tooltip
                      title={`${
                        pmExternal?.taskCategory ===
                        MaintenanceCategoriesEnum.CORRECTIVE_MAINTENANCE
                          ? AlectifyText.CORRECTIVE_MAINTENANCE
                          : AlectifyText.PREVENTIVE_MAINTENANCE
                      }`}
                    >
                      <AlectifyChip
                        text={
                          pmExternal?.taskCategory ===
                          MaintenanceCategoriesEnum.CORRECTIVE_MAINTENANCE
                            ? AlectifyText.CM
                            : AlectifyText.PM
                        }
                        textColor={
                          pmExternal?.taskCategory ===
                          MaintenanceCategoriesEnum.CORRECTIVE_MAINTENANCE
                            ? "red"
                            : "0954f1"
                        }
                        backgroundColor={
                          pmExternal?.taskCategory ===
                          MaintenanceCategoriesEnum.CORRECTIVE_MAINTENANCE
                            ? "#fde8e8"
                            : "e8f1fd"
                        }
                        borderColor={
                          pmExternal?.taskCategory ===
                          MaintenanceCategoriesEnum.CORRECTIVE_MAINTENANCE
                            ? "#ff0000"
                            : "e8f1fd"
                        }
                      />
                    </Tooltip>
                  )}
                </span>
              </Space>
            }
            extra={
              <Space size={1}>
                {asset?.name ? (
                  <AssetTag />
                ) : assetPackage?.name ? (
                  <AssetPackageTag />
                ) : (
                  <GenericTag />
                )}
              </Space>
            }
          />
          <AlectifyDescription
            detail={pmExternal?.detail || ""}
            height={DescriptionViewHeight.extraSmall}
          />
          <PmInfoCard
            pmData={pmExternal}
            teamMembers={teamMembers}
            loader={loader}
          />
          {!props.hideDetailBtn && (
            <div className="alectify-sticky-buttons-container">
              <AlectifyButton
                block
                type="primary"
                text={
                  props.pmType === PM_TYPES.TASK
                    ? AlectifyText.GO_TO_TASK_DETAIL
                    : AlectifyText.GO_TO_PM_DETAIL
                }
                className="mt-5 mb-10"
                onClick={goToPmDetail}
              />
            </div>
          )}
        </>
      </AlectifyDetailCard>
    </>
  );
};

PmDetail.defaultProps = {
  pmId: "",
};

export default memo(PmDetail);
