import React, { memo, useState, useEffect, useRef } from "react";
import { Breadcrumb, Col, message, Row } from "antd";
import AlectifyText from "static/texts.json";
import { useNavigate } from "react-router-dom";
import IncidentReportTable from "components/incident-report/IncidentReportTable";
import { ROUTES } from "routes/Routes.constants";
import {
  createIncidentReport,
  deleteIncidentDocument,
  deleteIncidentReports,
  getAllIncidentReports,
  updateIncidentReports,
  updateIncidentReportStatus,
} from "services/incident-reports/incident-reports.service";
import {
  IAllIncidentReportState,
  IIncidentDetail,
} from "pages/incident-report/Incident.interface";
import CreateIncidentReport from "components/incident-report/CreateIncidentReport";
import IncidentDetail from "components/incident-report/IncidentDetail";
import { IPagination } from "components/shared/table/AlectifyTable.interface";
import { PAGINATION, SERVER_DATE_FORMAT } from "constants/index";
import DrawerService from "components/shared/CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import { CloseOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { MESSAGES } from "constants/messages";
import DayJs from "dayjs";
import { extractTeamMembersId } from "utils/helpers";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import "./IncidentReport.scss";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import ConfirmationModal from "components/shared/confirmation/Confirmation";

const IncidentReport: React.FC = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const { activeMasterProject } = useSelector(
    ({ common }: IRootState) => common,
  );

  const {
    auth: { user },
  } = useSelector((state: IRootState) => state);

  const activeMasterProjectRef = useRef(activeMasterProject);

  const [isDetailPageActive, setDetailPageActive] = useState(false);
  const [currentIncident, setCurrentIncident] =
    useState<IIncidentDetail | null>(null);
  const updateIncidentRef = useRef<IIncidentDetail | null>(null);
  const [selectedIncidentId, setSelectedIncidentId] = useState<string | null>(
    null,
  );

  const [reportState, setReportState] = useState<IAllIncidentReportState>({
    data: [],
    fetching: false,
    meta: {
      currentPage: 1,
      itemCount: 0,
      itemsPerPage: 10,
      totalItems: 0,
      totalPages: 0,
    },
  });

  const selectedTeamTypeRef = useRef<string>("teams");
  const fileListRef = useRef<any>([]);
  const editModeRef = useRef<{ active: boolean; id: string }>({
    active: false,
    id: "",
  });

  useEffect(() => {
    activeMasterProjectRef.current = activeMasterProject;
  }, [activeMasterProject]);

  useEffect(() => {
    loadIncidentReports();
    closeIncidentDetail();
  }, [activeMasterProject]);

  const loadIncidentReports = async (paginationOptions?: IPagination) => {
    const { per_page, ...restOptions } = paginationOptions || {};
    const params = {
      projectId: activeMasterProjectRef.current?.id,
      page: paginationOptions?.page || PAGINATION.DEFAULT_START_PAGE,
      limit: paginationOptions?.limit || PAGINATION.DEFAULT_PAGE_SIZE,
      ...restOptions,
      teamMembers: [user?.id],
      createdById: user.id,
    };

    setReportState((prevState) => ({ ...prevState, fetching: true }));

    try {
      const response = await getAllIncidentReports(params as any);
      setReportState((prevState) => ({
        ...prevState,
        data: response?.data,
        meta: response?.meta,
        fetching: false,
      }));
    } catch (error) {
      console.error(error);
      setReportState((prevState) => ({ ...prevState, fetching: false }));
    }
  };

  const handleFileChange = ({ fileList }: any) => {
    fileListRef.current = fileList;
  };

  const handleCreateIncident = () => {
    editModeRef.current.active = false;
    openIncidentDrawer();
  };

  const handleSaveIncident = async (values: any, isDraft = false) => {
    try {
      setReportState((prevState) => ({ ...prevState, fetching: true }));
      const formData = prepareFormData(values, isDraft);

      const response = editModeRef.current.active
        ? await updateIncidentReports(editModeRef.current.id, formData)
        : await createIncidentReport(formData);

      handleApiResponse(response, editModeRef.current.active);
    } catch (error) {
      console.error(error);
      message.error(MESSAGES.API_RESPONSE_ERRORS.SOMETHING_WENT_WRONG);
    } finally {
      setReportState((prevState) => ({ ...prevState, fetching: false }));
      loadIncidentReports();
      setSelectedIncidentId(null);
    }
  };

  const prepareFormData = (values: any, isDraft: boolean) => {
    const formData = new FormData();
    const teamMembers =
      selectedTeamTypeRef.current === "users"
        ? values.teamMembers
        : [values.teamMembers];

    if (!editModeRef.current.active) {
      teamMembers.forEach((member: string) =>
        formData.append("teamMembers[]", member),
      );
    }
    formData.append("title", values.title || "-");
    formData.append("description", values.description || "-");
    formData.append("priority", values.priority);
    formData.append("projectId", values.masterProject);
    formData.append("isDraft", isDraft.toString());
    formData.append(
      "incidentDate",
      values?.incidentDate?.format(SERVER_DATE_FORMAT) || "-",
    );
    if (selectedTeamTypeRef.current === "teams") {
      formData.append("teamId", values.teamMembers || "");
    }
    if (values.documents?.fileList) {
      values.documents.fileList
        .filter((file: any) => file?.originFileObj)
        .forEach((file: any) =>
          formData.append("documents[]", file.originFileObj),
        );
    }

    return formData;
  };

  const handleApiResponse = (response: any, isEditMode: boolean) => {
    if (response) {
      message.success(
        isEditMode
          ? MESSAGES.INCIDENT_REPORTS.UPDATED
          : MESSAGES.INCIDENT_REPORTS.CREATED,
      );
    } else {
      message.error(MESSAGES.API_RESPONSE_ERRORS.SOMETHING_WENT_WRONG);
    }
  };

  const handleDeleteIncident = async (id: string) => {
    try {
      const response = await deleteIncidentReports(id);
      if (response) {
        message.success(MESSAGES.INCIDENT_REPORTS.DELETE);
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.INCIDENT_REMOVE_MODAL,
        });
        loadIncidentReports();
      } else {
        message.error(MESSAGES.API_RESPONSE_ERRORS.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDetailPageActive(false);
    }
  };
  const openDeleteConfirmationModal = (id: string) => {
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.INCIDENT_REMOVE_MODAL,
      title: "Delete Report",
      onOk: () => {
        handleDeleteIncident(id);
      },
      footer: null,
      closable: false,
      children: (
        <ConfirmationModal
          onConfirm={() => handleDeleteIncident(id)}
          message="Are you sure you want to remove this report?"
          onCancel={() =>
            ModalServiceInstance.close(AlectifyModal, {
              name: MODAL_NAMES.INCIDENT_REMOVE_MODAL,
            })
          }
        />
      ),
    });
  };

  const statusClosedConfirmationModal = (id: string) => {
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.INCIDENT_STATUS_MODAL,
      title: "Close Report",
      onOk: () => {
        handleIncidentReportStatus(id);
      },
      footer: null,
      closable: false,
      children: (
        <ConfirmationModal
          onConfirm={() => handleIncidentReportStatus(id)}
          message="Are you sure you want to close this report?"
          onCancel={() =>
            ModalServiceInstance.close(AlectifyModal, {
              name: MODAL_NAMES.INCIDENT_STATUS_MODAL,
            })
          }
        />
      ),
    });
  };

  const handleEditIncident = (record: IIncidentDetail) => {
    setFormValues(record);
    editModeRef.current = { active: true, id: record.id };
    openIncidentDrawer(record);
  };

  const setFormValues = (record: IIncidentDetail) => {
    fileListRef.current = record.documents;
    form.setFieldsValue({
      title: record.title,
      masterProject: record.project?.id,
      incidentDate: DayJs(record.incidentDate),
      priority: record.priority,
      description: record.description,
      documents: record.documents,
      teamMembers: extractTeamMembersId(
        !isEmpty(record.teamMembers)
          ? record.teamMembers
          : record?.team?.projectTeamMembers,
      ),
    });
  };

  const handleUserTypeChange = (value: string) => {
    selectedTeamTypeRef.current = value;
    updateIncidentRef.current = null;
    form.setFieldValue("teamMembers", undefined);
  };

  const handleIncidentDetail = (record: IIncidentDetail) => {
    setDetailPageActive(true);
    setCurrentIncident(record);
  };

  const closeIncidentDetail = () => {
    setDetailPageActive(false);
    setSelectedIncidentId(null);
  };

  const handleSaveAsDraft = async () => {
    try {
      const values = await form.validateFields();
      await handleSaveIncident(values, true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDocumentDelete = async (id: string) => {
    try {
      if (!isEmpty(id)) {
        const response = await deleteIncidentDocument(id);
        if (response.status) {
          message.success(MESSAGES.INCIDENT_REPORTS.FILE_DELETED);
        }
        loadIncidentReports();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleIncidentReportStatus = async (id: string) => {
    try {
      if (!isEmpty(id)) {
        const payload = {
          status: "CLOSED",
        };
        const response = await updateIncidentReportStatus(id, payload);
        if (response.status) {
          message.success(MESSAGES.INCIDENT_REPORTS.STATUS_UPDATED);
        }
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.INCIDENT_STATUS_MODAL,
        });
        loadIncidentReports();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const openIncidentDrawer = (record?: any) => {
    if (!editModeRef.current.active) {
      fileListRef.current = [];
    }
    const incidentRecord = record?.target ? null : record;
    updateIncidentRef.current = incidentRecord;
    DrawerService.open(AlectifyDrawer, {
      width: "80vw",
      title: editModeRef.current.active
        ? AlectifyText.UPDATE_REPORT
        : AlectifyText.CREATE_REPORT,
      name: DRAWER_CONSTANTS.CREATE_INCIDENT_REPORT,
      showFooter: false,
      onClose: closeDrawer,
      closeIcon: <CloseOutlined />,
      closable: true,
      destroyOnClose: true,
      children: (
        <CreateIncidentReport
          formInstance={form}
          reportRecord={updateIncidentRef.current}
          onCloseDrawer={closeDrawer}
          isEditModeRef={editModeRef}
          onReportSubmit={handleSaveIncident}
          initialFileList={fileListRef.current}
          onUserTypeChange={handleUserTypeChange}
          handleFileChange={handleFileChange}
          onSaveAsDraft={handleSaveAsDraft}
          onDocumentDelete={handleDocumentDelete}
        />
      ),
    });
  };

  const closeDrawer = () => {
    setDetailPageActive(false);
    DrawerService.close(AlectifyDrawer, {
      name: DRAWER_CONSTANTS.CREATE_INCIDENT_REPORT,
    });
    form.resetFields();
    setSelectedIncidentId(null);
  };

  return (
    <div className="incident-report-main">
      <div className="breadcrumb-container">
        <Breadcrumb
          items={[
            {
              title: AlectifyText.HOME,
              href: "#",
              onClick: () => navigate(ROUTES.PROJECT),
            },
            { title: AlectifyText.INCIDENT_REPORTS },
          ]}
        />
      </div>
      <div className="content-container">
        <Row gutter={12} className="height-100">
          <Col span={isDetailPageActive ? 12 : 24} className="report-table">
            <IncidentReportTable
              allReports={reportState}
              onEdit={handleEditIncident}
              openCreateIncident={handleCreateIncident}
              selectedRowId={selectedIncidentId}
              setSelectedRowId={setSelectedIncidentId}
              onDeleteRecord={openDeleteConfirmationModal}
              onDeleteDocuments={handleDocumentDelete}
              onStatusUpdate={statusClosedConfirmationModal}
              handleIncidentDetail={handleIncidentDetail}
              fetchAllIncidentReports={loadIncidentReports}
            />
          </Col>
          <Col
            span={12}
            className={`detail-page ${
              isDetailPageActive ? "active-detail-page" : ""
            }`}
          >
            {isDetailPageActive && (
              <IncidentDetail
                detail={currentIncident}
                closeIncidentDetail={closeIncidentDetail}
              />
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default memo(IncidentReport);
