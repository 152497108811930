import { get, truncate } from "lodash";
import { memo } from "react";
import { Tooltip, Typography } from "antd";
import { ICalendarEvent } from "services/calendar/calendar.interface";
import { CalendarEventStatusEnum, CalendarEventTypesEnum } from "enums";
import {
  EventCompletedIcon,
  EventCompletedPastDueDateIcon,
  EventDelayedIcon,
  EventPendingIcon,
} from "components/icons";
import TasksIcon from "components/icons/TasksIcon";
import PmExternalIcon from "components/icons/PmExternalIcon";
import PmInternalIcon from "components/icons/PmInternalIcon";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import PmDetail from "components/shared/pm-detail";
import EventSkippedIcon from "components/icons/EventSkippedIcon";
import { IRootState } from "redux/rootReducer";
import { useSelector } from "react-redux";
import AlectifyText from "static/texts.json";
import { CloseOutlined } from "@ant-design/icons";

export const EVENT_ICONS = {
  [CalendarEventTypesEnum.TASKS]: <TasksIcon />,
  [CalendarEventTypesEnum.PM_EXTERNAL]: <PmExternalIcon />,
  [CalendarEventTypesEnum.PM_INTERNAL]: <PmInternalIcon />,
};

export const EVENT_STATUS_ICONS = {
  [CalendarEventStatusEnum.DELAYED]: <EventDelayedIcon />,
  [CalendarEventStatusEnum.PENDING]: <EventPendingIcon />,
  [CalendarEventStatusEnum.ON_TIME_COMPLETED]: <EventCompletedIcon />,
  [CalendarEventStatusEnum.DELAYED_COMPLETED]: (
    <EventCompletedPastDueDateIcon />
  ),
  [CalendarEventStatusEnum.SKIPPED]: <EventSkippedIcon />,
};

export const EVENT_CLASSES: any = {
  [CalendarEventStatusEnum.DELAYED]: "delayed",
  [CalendarEventStatusEnum.PENDING]: "pending",
  [CalendarEventStatusEnum.ON_TIME_COMPLETED]: "completed",
  [CalendarEventStatusEnum.DELAYED_COMPLETED]: "completed",
  [CalendarEventStatusEnum.SKIPPED]: "skipped",
};

const CalendarEvent: React.FC<ICalendarEvent> = (props: ICalendarEvent) => {
  const masterProject = get(props, "master_project[0]");
  const { showCalendarMyWorkOrders } = useSelector(
    (state: IRootState) => state.common,
  );

  const openDetailDrawer = async (
    props: any,
    id: string,
    type: string,
    title: string,
  ) => {
    DrawerServiceInstance.open(AlectifyDrawer, {
      width: 420,
      title: title || "Detail",
      name: DRAWER_CONSTANTS.PM_EXTERNAL_DETAIL_DRAWER,
      closeIcon: <CloseOutlined />,
      closable: true,
      onClose: () =>
        DrawerServiceInstance.close(AlectifyDrawer, {
          name: DRAWER_CONSTANTS.PM_EXTERNAL_DETAIL_DRAWER,
        }),
      children: (
        <PmDetail
          record={props}
          pmId={id}
          pmType={type as any}
          hideDetailBtn={!showCalendarMyWorkOrders}
        />
      ),
    });
  };

  const tooltipTitle = () => (
    <>
      <Typography.Text className="calender-card-tooltip-font-color">
        {AlectifyText.WORK_TITLE} : {props?.title || "-"}
      </Typography.Text>
      <br />
      <Typography.Text className="calender-card-tooltip-font-color">
        {get(props, "asset[0].name")
          ? `${AlectifyText.ASSET_NAME}: ${get(props, "asset[0].name") || "-"}`
          : `${AlectifyText.PARENT_ASSET_NAME}: ${
              get(props, "asset_package[0].name") || "-"
            }`}
      </Typography.Text>
      <br />
      <Typography.Text className="calender-card-tooltip-font-color">
        {AlectifyText.WORKID} : {props?.work_id || "-"}
      </Typography.Text>
      <br />
      <Typography.Text className="calender-card-tooltip-font-color">
        {AlectifyText.SITE} : {masterProject?.name || "-"}
      </Typography.Text>
    </>
  );

  return (
    <Tooltip title={tooltipTitle}>
      <div
        className={`alectify-calendar-event ${
          EVENT_CLASSES[props.event_status as CalendarEventStatusEnum]
        }`}
        style={{ backgroundColor: masterProject.color }}
        onClick={() => {
          openDetailDrawer(props, props.id, props.type, props.title);
        }}
      >
        {/* <Space> */}
        <div
          className={`event-icon ${
            EVENT_CLASSES[props.event_status as CalendarEventStatusEnum]
          }`}
        >
          {EVENT_ICONS[props.type as CalendarEventTypesEnum]}
          <div className="event-status-icon">
            {EVENT_STATUS_ICONS[props.event_status as CalendarEventStatusEnum]}
          </div>
        </div>
        <div className="event-content">
          <div className="event-title">
            <Typography.Text>
              {truncate(props?.title || "NA", { length: 20, omission: "..." })}
            </Typography.Text>
          </div>
          {/* <Space
                    direction={diff === 0 ? "vertical" : "horizontal"}
                    className="event-info"
                  > */}
          <Typography.Text>{`${truncate(
            get(props, "asset[0].name")
              ? get(props, "asset[0].name") || "-"
              : get(props, "asset_package[0].name") || "-",
            { length: 20, omission: "..." },
          )}`}</Typography.Text>
          {/* <Typography.Text>{`${
                    asset?.id ? AlectifyText.ASSET : "AP"
                  }: ${asset?.name ?? assetPackage?.name}`}</Typography.Text> */}
          {/* </Space> */}
        </div>
        {/* </Space> */}
      </div>
    </Tooltip>
  );
};

export default memo(CalendarEvent);
