import { Form, Spin, Typography } from "antd";
import { ICalendarFiltersProps } from "./Calendar.interface";
// import SelectSubProject from "components/shared/sub-project-select/SelectSubProject";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import AlectifyText from "static/texts.json";
import { fetchDropdownMasterProjects } from "services/master-project/masterProject.service";
import { IMasterProject } from "redux/components/master-project";
import { isEmpty } from "lodash";

const CalendarFilters: React.FC<ICalendarFiltersProps> = (
  props: ICalendarFiltersProps,
) => {
  const { activeMasterProject, showCalendarMyWorkOrders } = useSelector(
    (state: IRootState) => state.common,
  );
  const [masterProjects, setMasterProjects] = useState<IMasterProject[]>([]);

  const [loader, setLoader] = useState<boolean>(false);

  // const allProjectsOption: any = {
  //   id: "all",
  //   name: "All Sites",
  //   color: "#ffffff",
  // };

  const getMasterProjects = async () => {
    setLoader(true);
    try {
      const params: any = {};
      if (!showCalendarMyWorkOrders && showCalendarMyWorkOrders !== undefined) {
        params.filter_by = "user_branch";
      }
      const response = await fetchDropdownMasterProjects(params);
      setMasterProjects([...(response.data as any)]);
      setLoader(false);
    } catch (ex) {
      setLoader(false);
      console.log(ex);
    }
  };

  useEffect(() => {
    getMasterProjects();
    props.FormInstance.resetFields();
  }, [activeMasterProject?.id]);
  const activeElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (activeMasterProject?.id && activeElementRef.current) {
      activeElementRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [activeMasterProject?.id, masterProjects]);
  return (
    <>
      {/* <Typography.Text>{"Selected Site"}</Typography.Text> */}
      {/*  <div className="masterproject-options">
        <div
          key={activeMasterProject?.id || "all"}
          className={`masterproject-option active`}
        >
          <div
            className="masterproject-circle-color"
            style={{
              backgroundColor: activeMasterProject?.color,
            }}
          />
          <div>{activeMasterProject?.name || AlectifyText.ALL_SITES}</div>
        </div>
      </div> */}
      {/* <Spin spinning={loader}> */}
      <Typography.Text>{AlectifyText.SITE}</Typography.Text>
      <div className="masterproject-options">
        {isEmpty(activeMasterProject) ? (
          masterProjects.map((masterProject) => {
            // const isActive = masterProject.id === activeMasterProject?.id;
            return (
              <div
                key={masterProject.id}
                className={`masterproject-option`}
                id={masterProject.id}
                // ref={isActive ? activeElementRef : null}
              >
                {/* <Radio
                onChange={() =>
                  props.onSelectMasterProject(masterProject.name, masterProject)
                }
                className="masterproject-text"
                checked={isActive ?? false}
              > */}
                {/* </Radio> */}
                <div
                  className="masterproject-circle-color"
                  style={{
                    backgroundColor: masterProject.color,
                  }}
                />
                <div>{masterProject?.name || AlectifyText.ALL_SITES}</div>
              </div>
            );
          })
        ) : (
          <div
            key={activeMasterProject.id}
            className={`masterproject-option active`}
            id={activeMasterProject.id}
            // ref={isActive ? activeElementRef : null}
          >
            {/* <Radio
          onChange={() =>
            props.onSelectMasterProject(masterProject.name, masterProject)
          }
          className="masterproject-text"
          checked={isActive ?? false}
        > */}
            {/* </Radio> */}
            <div
              className="masterproject-circle-color"
              style={{
                backgroundColor: activeMasterProject.color,
              }}
            />
            <div>{activeMasterProject?.name || AlectifyText.ALL_SITES}</div>
          </div>
        )}
      </div>
      {/* </Spin> */}

      {/* {activeMasterProject && (
        <div className="mt-20">
          <SelectSubProject
            masterProjectId={common.activeMasterProject?.id || "all"}
            onSelect={props.onSelectSubProject}
            addAllFilter={true}
            disabled={
              common.activeMasterProject?.id === "all" ||
              isEmpty(common.activeMasterProject)
            }
          />
        </div>
      )} */}

      {/* <div className="text-align-right">
        <AlectifyButton text="Filter" className="ant-btn-primary" />
      </div> */}
    </>
  );
};

export default CalendarFilters;
