import { PME_ACTION_REQUIRED_ENUM, PM_STATUS } from "enums";
import { isEmpty } from "lodash";
import dayjs from "dayjs";
import { IUser } from "redux/components/Auth";
import { IPmExternal } from "redux/components/pm-external";

export const isAssignee = (pmExternal: IPmExternal, currentUser: IUser) => {
  if (!isEmpty(pmExternal.assignees)) {
    return pmExternal.assignees.some(
      (assignee) => assignee.user.id === currentUser.id,
    );
  }
  return false;
};

/**
 * Creates PM External action required value based on task status
 *
 * @param {IPmExternal} pmExternal - PM External object
 * @returns A string for action required column to display
 */
export const getPMEActionRequired = (
  pmExternal: IPmExternal,
): PME_ACTION_REQUIRED_ENUM | null => {
  let actionRequired: PME_ACTION_REQUIRED_ENUM | null;
  if (
    pmExternal.status === PM_STATUS.PENDING ||
    pmExternal.status === PM_STATUS.INPROGRESS
  ) {
    actionRequired = PME_ACTION_REQUIRED_ENUM.ASSIGNEE;
  } else if (pmExternal.status === PM_STATUS.WAITING_FOR_REVIEW) {
    actionRequired = PME_ACTION_REQUIRED_ENUM.APPROVERS;
  } else {
    actionRequired = null;
  }
  return actionRequired;
};

export const isDelayed = (completedAt: Date, dueDate: Date) => {
  const completedMoment = dayjs(completedAt);
  const dueMoment = dayjs(dueDate);
  return completedMoment.isAfter(dueMoment);
};
