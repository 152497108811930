import React from "react";
import { Form, Select } from "antd";
import { IAlectifySelectProps } from "./AlectifySelect.interface";

import "./AlectifySelect.scss";

const AlectifySelect: React.FC<IAlectifySelectProps> = (
  props: IAlectifySelectProps,
) => {
  const { label, name, rules, placeholder } = props;
  return !props.noFormItem ? (
    <Form.Item
      name={name}
      rules={rules}
      label={label}
      className={`alectify-select ${props.className}`}
      tooltip={props.tooltip}
    >
      <Select
        mode={props.mode}
        allowClear={props.allowClear}
        options={props.options}
        onChange={props.onChange}
        placeholder={placeholder}
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        showSearch={props.showSearch}
        onSearch={props.onSearch}
        loading={props.loading}
        onSelect={props.onSelect}
        onDeselect={props.onDeselect}
        maxTagCount={props.maxTagCount}
      />
    </Form.Item>
  ) : (
    <Select {...props} className={`alectify-select ${props.className}`} />
  );
};

AlectifySelect.defaultProps = {
  className: "",
  placeholder: "",
  noFormItem: false,
};

export default AlectifySelect;
