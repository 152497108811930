import React, { ReactNode } from "react";
import {
  Avatar,
  Col,
  message,
  Row,
  Space,
  Spin,
  Steps,
  Typography,
  Upload,
} from "antd";
import { IWorkOrderBasicInfoProps } from "./WorkOrderPMDetails.interface";
import TextToLink from "components/shared/text-to-link";
import StatusCard from "components/shared/status-card/StatusCard";
import AlectifyDescription from "components/shared/detailed-view/AlectifyDescriptionView";
import { DescriptionViewHeight } from "components/shared/detailed-view/AlectifyDescriptionView.interface";
import { isEmpty, truncate } from "lodash";
import { ITask } from "redux/components/tasks";
import AlectifyEmptyState from "components/shared/empty/AlectifyEmptyState";
import { displayDateTime } from "utils/helpers";
import {
  NO_IMAGE_AVATAR,
  NO_PHOTO_AVAILABLE,
} from "components/shared/assets-table/AssetsTable.constants";
import {
  GenericTag,
  NonRecurringTag,
  RecurringTag,
} from "components/shared/tags";
import { useNavigate } from "react-router-dom";
import { PME_ACTION_REQUIRED_ENUM, TaskPriorityEnum } from "enums";
import DayJs from "dayjs";
import {
  IProcedure,
  IProcedureCheckList,
} from "services/procedures/procedures.interface";
import { DONE_TASK_GREEN_COLOR } from "components/shared/phone-number/constants";
import StampCheckIcon from "components/icons/StampCheckIcon";
import { countCheckedSteps } from "pages/procedures/Procedures.helpers";
import InventoryIcon from "components/icons/InventoryIcon";
import { getPMEActionRequired } from "components/shared/pm-external-table/PmExternal.helpers";
import "./WorkOrderBasicInfo.scss";
import { FrequencyTypeEnum } from "components/shared/pm-create-edit-form/PMCreateEditForm.interace";
import { TASK_PROIRITY_COMPONENTS } from "components/shared/tasks-table/effects/useTasksColumns";
import AlectifyText from "static/texts.json";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import ViewImage from "components/shared/view-image/ViewImage";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import { CloseOutlined } from "@ant-design/icons";
import AssetDetail from "components/shared/asset-detail";
import PackageDetailAssets from "components/shared/package-detail-assets";
import { StepProps } from "antd/lib";
import { MESSAGES } from "constants/messages";
import { updatePrevMaintenances } from "services/pm-external/pm-external.service";

const WorkOrderBasicInfo: React.FC<IWorkOrderBasicInfoProps> = (
  props: IWorkOrderBasicInfoProps,
) => {
  const navigate = useNavigate();

  const getParentAsset = (task: ITask | null): ReactNode => {
    if (!isEmpty(task) && !isEmpty(task.asset?.area)) {
      return (
        <>
          <TextToLink
            text={(task.asset?.area && task.asset?.area[0].name) || "-"}
            onClick={() => {
              DrawerServiceInstance.open(AlectifyDrawer, {
                width: 900,
                title:
                  (task.asset?.area &&
                    truncate(task.asset?.area[0].name, {
                      length: 20,
                      omission: "...",
                    })) ||
                  "-",
                name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
                closable: true,
                closeIcon: <CloseOutlined />,
                onClose: () =>
                  DrawerServiceInstance.close(AlectifyDrawer, {
                    name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
                  }),
                children: (
                  <PackageDetailAssets
                    taskRecord={props.task}
                    subProjectId={props.task?.subProject?.id || ""}
                    assetPackageId={
                      (task.asset?.area && task.asset?.area[0].id) || ""
                    }
                    navigate={navigate}
                    isDetailsbutton
                    isUploadbutton
                  />
                ),
              });
            }}
          />
        </>
      );
    }
    return "-";
  };

  const getTaskStatusIcon = (procedure: IProcedure): JSX.Element => {
    const isAllChecked = procedure.procedureSteps.every(
      (steps: IProcedureCheckList) => steps.isChecked,
    );
    return isAllChecked ? (
      <StampCheckIcon fill={DONE_TASK_GREEN_COLOR} />
    ) : (
      <StampCheckIcon />
    );
  };

  const getActionRequiredComponent = () => {
    const actionRquired = getPMEActionRequired(props.task as any);
    if (!isEmpty(props.task?.assignees) || !isEmpty(props.task?.approvers)) {
      return (
        <StatusCard
          text={
            actionRquired === PME_ACTION_REQUIRED_ENUM.ASSIGNEE
              ? "Assginees"
              : "Approvers"
          }
          users={
            (actionRquired === PME_ACTION_REQUIRED_ENUM.ASSIGNEE
              ? props.task?.assignees.map(({ user }) => user)
              : props.task?.approvers?.map(({ user }) => user)) || []
          }
          helperText="Action Required"
        />
      );
    }
  };

  const openImagePreviewModal = (url: string) => {
    ModalServiceInstance.open(AlectifyModal, {
      onCancel: () => {
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.TASK_TABLE_IMAGE_MODAL,
        });
      },
      name: MODAL_NAMES.TASK_TABLE_IMAGE_MODAL,
      title: "Preview",
      footer: null,
      children: <ViewImage imageUrl={url} />,
    });
  };

  const getStepsItems = (): StepProps[] => {
    const items: StepProps[] = [
      {
        title: "Created Work Order",
        description: (
          <Space direction="vertical" size={1}>
            <span className="status-description-step-date-text">
              {`${displayDateTime(props.task?.createdAt, true, false)}`}
            </span>
            {props.task?.createdAt && (
              <span className="completed-text">Completed</span>
            )}
          </Space>
        ),
        status: (!props.task?.createdAt && "error") || "finish",
        icon: (
          <Avatar
            size={"large"}
            src={props.task?.createdBy?.image_url || NO_IMAGE_AVATAR}
          />
        ),
      },
      {
        title: (
          <Space>
            <span>{AlectifyText.SUBMITTED_REVIEW}</span>
            {props.task?.reviewedAt && (
              <TextToLink
                text="(Information Submitted)"
                onClick={() => props.submittedInformationModal()}
              />
            )}
          </Space>
        ),
        description: (
          <Space direction="vertical" size={1}>
            {props.task?.completionAt &&
              `${
                (props.task?.reviewedAt &&
                  displayDateTime(
                    (props.task?.reviewedAt as any) || "-",
                    true,
                    false,
                  )) ||
                "-"
              }`}
            {(props.task?.completionAt && (
              <span className="completed-text">Completed</span>
            )) || <span>Not Done</span>}
          </Space>
        ),
        status: (props.task?.completionAt && "finish") || "error",
        icon: props.task?.reviewedBy?.image_url && (
          <Avatar size={"large"} src={props.task?.reviewedBy?.image_url} />
        ),
      },
    ];
    if (!props.task?.skippedAt) {
      items.push({
        title: AlectifyText.REVIEW_COMPLETED,
        description: (
          <Space direction="vertical" size={1}>
            {props.task?.completedAt &&
              `${
                (props.task?.completedAt &&
                  displayDateTime(
                    props.task?.completedAt as any,
                    true,
                    false,
                  )) ||
                "-"
              }`}
            {(props.task?.completedAt && (
              <span className="completed-text">Completed</span>
            )) || <span>Not Done</span>}
          </Space>
        ),
        status: (props.task?.completedAt && "finish") || "error",
        icon: props.task?.completedBy?.image_url && (
          <Avatar size={"large"} src={props.task?.completedBy?.image_url} />
        ),
      });
    }
    if (props.task?.skippedAt) {
      items.push({
        title: AlectifyText.SKIPPED,
        description: (
          <Space direction="vertical" size={1}>
            {props.task?.skippedAt &&
              `${
                (props.task?.skippedAt &&
                  displayDateTime(props.task?.skippedAt as any, true, false)) ||
                "-"
              }`}
            {(props.task?.skippedAt && (
              <span className="completed-text">Completed</span>
            )) || <span>Not Done</span>}
          </Space>
        ),
        status: (props.task?.skippedAt && "finish") || "error",
        icon: props.task?.skippedBy?.image_url && (
          <Avatar size={"large"} src={props.task?.skippedBy?.image_url} />
        ),
      });
    }
    return items;
  };

  const handleFileUpload = async (file: any) => {
    const { task } = props;

    try {
      props.setLoader?.(true);
      const formData = new FormData();

      if (task && !isEmpty(task)) {
        const taskFields = [
          { key: "taskCategory", value: task.taskCategory },
          { key: "pm_tracking", value: "asset" },
          { key: "assetLevel", value: task.asset ? "asset" : "area" },
          { key: "workTitle", value: task.workTitle },
          { key: "priority", value: task.priority || "-" },
          { key: "detail", value: task.detail || "-" },
          { key: "projectId", value: task.project?.id },
          { key: "subProjectId", value: task.subProject?.id },
          { key: "dayType", value: task.dayType },
          { key: "day", value: task.day },
          { key: "date", value: task.date },
          { key: "week", value: task.week },
          { key: "frequencyType", value: task.frequencyType?.toUpperCase() },
          { key: "frequency", value: task.frequency },
          { key: "isRecurring", value: task.isRecurring.toString() },
          {
            key: "changeAllFuturePM",
            value: task.isRecurring ? "true" : "false",
          },
          { key: "assetId", value: task.asset?.id },
          { key: "areaId", value: task.area?.id },
          { key: "procedureLibraryId", value: task.procedureLibraryId as any },
          { key: "image", value: file },
          {
            key: "dueDate",
            value:
              task.dueDate && DayJs(task.dueDate).hour(6).minute(0).format(),
          },
        ];

        taskFields.forEach(({ key, value }) => {
          if (value) formData.append(key, value);
        });
      }

      const response = await updatePrevMaintenances(task?.id || "-", formData);
      if (response.status) {
        message.success(MESSAGES.GENERAL_MESSAGES.IMAGE_UPLOADED);
      }
    } catch (error: any) {
      message.error(MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG);
      console.error(error);
    } finally {
      props.setLoader?.(false);
      props.getPmExternalDetails?.(task?.id);
    }
  };

  return (
    <Spin spinning={props.loader}>
      <div className="alectify-pm-basic-info-container">
        {(!isEmpty(props.task) && (
          <>
            <Row justify={"start"} align={"top"} gutter={20}>
              <Col span={4}>
                <div className="alectify-pm-basic-info-image-card">
                  <div
                    className={`alectify-pm-basic-info-image ${
                      props.task?.imageUrl ? "cursor-pointer" : ""
                    }`}
                    onClick={() =>
                      props.task?.imageUrl &&
                      openImagePreviewModal(props.task?.imageUrl)
                    }
                    style={{
                      backgroundImage: `url(${
                        props.task?.imageUrl || NO_PHOTO_AVAILABLE
                      })`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      width: "100%",
                      height: "150px",
                    }}
                  ></div>
                  <Upload
                    maxCount={1}
                    accept="image/*"
                    onChange={(info) => {
                      const { fileList } = info;
                      handleFileUpload(fileList[0].originFileObj);
                    }}
                    fileList={[]}
                    beforeUpload={() => false}
                  >
                    <div className="edit-profile-text">
                      {props.task?.imageUrl ? "Change Image" : "Add Image"}
                    </div>
                  </Upload>
                </div>
              </Col>
              <Col span={20}>
                <Row justify={"space-between"} align={"middle"}>
                  <Col span={12}>
                    <span className="date-chip">
                      <Space>
                        <strong>Due Date:</strong>
                        {displayDateTime(props.task.dueDate) || "-"}
                      </Space>
                    </span>
                  </Col>

                  <Col span={12} className="text-align-right">
                    {props.actionButton}
                  </Col>
                  <Col span={24} className="mb-20">
                    {(!props.task?.isGeneric && (
                      <div className="alectify-pm-basic-info-asset-chip">
                        <Space>
                          {!isEmpty(props.task?.asset) && (
                            <>
                              <span>Sub Asset:</span>
                              <TextToLink
                                text={
                                  (!isEmpty(props.task?.asset) &&
                                    props.task?.asset.name) ||
                                  "-"
                                }
                                onClick={() => {
                                  DrawerServiceInstance.open(AlectifyDrawer, {
                                    width: props.task?.asset?.name ? 420 : 900,
                                    title: truncate(
                                      props.task?.asset?.name ||
                                        props.task?.area?.name,
                                      {
                                        length: 30,
                                        omission: "...",
                                      },
                                    ),
                                    name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
                                    closable: true,
                                    closeIcon: <CloseOutlined />,
                                    onClose: () =>
                                      DrawerServiceInstance.close(
                                        AlectifyDrawer,
                                        {
                                          name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
                                        },
                                      ),
                                    children: !isEmpty(props.task?.asset) ? (
                                      <AssetDetail
                                        taskRecord={props.task}
                                        subProjectId={
                                          props.task?.subProject?.id
                                        }
                                        assetId={props.task?.asset?.id}
                                        navigate={navigate}
                                        isDetailButton
                                        isUploadbutton
                                      />
                                    ) : (
                                      <PackageDetailAssets
                                        taskRecord={props.task}
                                        subProjectId={
                                          props.task?.subProject?.id || ""
                                        }
                                        assetPackageId={
                                          props.task?.area?.id || ""
                                        }
                                        navigate={navigate}
                                        isDetailsbutton
                                        isUploadbutton
                                      />
                                    ),
                                  });
                                }}
                              />
                              <span>{"( Parent Asset:"}</span>
                              <span>{getParentAsset(props.task)} )</span>
                            </>
                          )}
                          {!isEmpty(props.task?.area) && (
                            <>
                              <span>Parent Asset:</span>
                              <TextToLink
                                text={
                                  (!isEmpty(props.task?.area) &&
                                    props.task?.area.name) ||
                                  "-"
                                }
                                onClick={() => {
                                  DrawerServiceInstance.open(AlectifyDrawer, {
                                    width: props.task?.asset?.name ? 420 : 900,
                                    title: truncate(
                                      props.task?.asset?.name ||
                                        props.task?.area?.name,
                                      {
                                        length: 30,
                                        omission: "...",
                                      },
                                    ),
                                    name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
                                    closable: true,
                                    closeIcon: <CloseOutlined />,
                                    onClose: () =>
                                      DrawerServiceInstance.close(
                                        AlectifyDrawer,
                                        {
                                          name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
                                        },
                                      ),
                                    children: !isEmpty(props.task?.asset) ? (
                                      <AssetDetail
                                        taskRecord={props.task}
                                        subProjectId={
                                          props.task?.subProject?.id
                                        }
                                        assetId={props.task?.asset?.id}
                                        navigate={navigate}
                                        isDetailButton
                                        isUploadbutton
                                      />
                                    ) : (
                                      <PackageDetailAssets
                                        taskRecord={props.task}
                                        subProjectId={
                                          props.task?.subProject?.id || ""
                                        }
                                        assetPackageId={
                                          props.task?.area?.id || ""
                                        }
                                        navigate={navigate}
                                        isDetailsbutton
                                        isUploadbutton
                                      />
                                    ),
                                  });
                                }}
                              />
                            </>
                          )}
                        </Space>
                      </div>
                    )) || <GenericTag />}
                  </Col>
                </Row>
                {/* <Row justify={"start"} align={"middle"}>
                  <Col span={24}>
                    <Space direction="horizontal">
                      {PM_STATUS_COMPONENTS[props.task?.status]}
                    </Space>
                  </Col>
                </Row> */}
                <Row justify={"start"} gutter={20} align={"middle"}>
                  <Col span={8}>{getActionRequiredComponent()}</Col>
                  <Col span={8}>
                    <StatusCard
                      text="Procedure Checklist"
                      count={countCheckedSteps(
                        props.task?.procedure?.procedureSteps || [],
                      )}
                      onClick={() => props.setActiveTab("procedureSteps")}
                      prefix={
                        (!isEmpty(props.task?.procedure) && (
                          <span className="cursor-pointer">
                            {!isEmpty(props.task.procedure) &&
                              getTaskStatusIcon(props.task.procedure)}
                          </span>
                        )) || <StampCheckIcon />
                      }
                    />
                  </Col>
                  <Col span={8}>
                    <StatusCard
                      text="Spare Parts Used"
                      prefix={<InventoryIcon />}
                      onClick={() => props.setActiveTab("spare-parts")}
                      count={
                        (props.task?.sparePartsUsed?.count &&
                          String(props.task?.sparePartsUsed?.count)) ||
                        "0"
                      }
                      users={
                        (!isEmpty(props.task?.team) &&
                          props.task?.team.projectTeamMembers.map(
                            (d) => d.user,
                          )) ||
                        (!isEmpty(props.task.teamMembers) &&
                          props.task.teamMembers?.map((d: any) => d.user)) ||
                        []
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* <Divider /> */}
            <Row justify={"start"} align={"top"} className="mb-10 mt-5">
              <Col span={24}>
                <Typography.Title level={5} className="mt-0">
                  Progress so far
                </Typography.Title>
              </Col>
              <Col span={24} className="pm-detail-info-steps">
                <Steps current={3} status={"process"} items={getStepsItems()} />
              </Col>
            </Row>
            <Row justify={"start"} align={"top"} className="mb-10">
              <Col span={24}>
                <Typography.Title level={5} className="mt-0">
                  Detail
                </Typography.Title>
              </Col>
              <Col span={24}>
                <div className="alectify-pm-info-detail-container">
                  <Row justify={"start"} align={"middle"}>
                    <Col span={4}>
                      <Space direction="vertical">
                        <span className="alectify-pm-info-detail-key">
                          Work Order Id
                        </span>
                        <span className="alectify-pm-info-detail-value">
                          {props.task.workId || "-"}
                        </span>
                      </Space>
                    </Col>
                    <Col span={4}>
                      <Space direction="vertical">
                        <span>Site</span>
                        <TextToLink
                          text={props.task.project.name || "-"}
                          onClick={() =>
                            navigate(props.prepareRoutes("projects"))
                          }
                        />
                      </Space>
                    </Col>
                    <Col span={4}>
                      <Space direction="vertical">
                        <span className="alectify-pm-info-detail-key">
                          Asset Category
                        </span>
                        <TextToLink
                          className="alectify-pm-info-detail-value"
                          text={props.task.subProject?.name || "-"}
                          onClick={() =>
                            navigate(props.prepareRoutes("subproject"))
                          }
                        />
                      </Space>
                    </Col>
                    <Col span={4}>
                      <Space direction="vertical">
                        <span className="alectify-pm-info-detail-key">
                          Priority
                        </span>
                        <span className="alectify-pm-info-detail-value">
                          {TASK_PROIRITY_COMPONENTS[
                            props.task?.priority as TaskPriorityEnum
                          ] || "-"}
                        </span>
                      </Space>
                    </Col>
                    <Col span={4}>
                      <Space direction="vertical">
                        <span className="alectify-pm-info-detail-key">
                          Frequency Type
                        </span>
                        {props.task.isRecurring ? (
                          <RecurringTag />
                        ) : (
                          <NonRecurringTag />
                        )}
                      </Space>
                    </Col>
                    <Col span={4}>
                      <Space direction="vertical">
                        <span className="alectify-pm-info-detail-key">
                          Frequency
                        </span>
                        <span className="alectify-pm-info-detail-value">
                          {props.task.frequencyType ===
                          FrequencyTypeEnum.MONTHLY
                            ? `${props.task.frequency} month(s)`
                            : props.task.frequencyType ===
                              FrequencyTypeEnum.WEEKLY
                            ? "Weekly"
                            : props.task.frequencyType ===
                              FrequencyTypeEnum.DAILY
                            ? "Daily"
                            : "-"}
                        </span>
                      </Space>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row justify={"start"} align={"top"} gutter={20}>
              <Col span={24}>
                <Typography.Title level={5} className="mt-0">
                  Team Assignments
                </Typography.Title>
              </Col>
              <Col span={8}>
                <StatusCard
                  text="Approvers"
                  helperText={
                    getPMEActionRequired(props.task as any) ===
                    PME_ACTION_REQUIRED_ENUM.APPROVERS
                      ? "Action Required"
                      : ""
                  }
                  users={props.task?.approvers?.map(({ user }) => user) || []}
                />
              </Col>
              <Col span={8}>
                <StatusCard
                  text="Assignees"
                  helperText={
                    getPMEActionRequired(props.task as any) ===
                    PME_ACTION_REQUIRED_ENUM.ASSIGNEE
                      ? "Action Required"
                      : ""
                  }
                  users={props.task?.assignees?.map(({ user }) => user) || []}
                />
              </Col>
              <Col span={8}>
                <StatusCard
                  text="Team Members"
                  users={
                    (!isEmpty(props.task?.team) &&
                      props.task?.team.projectTeamMembers.map((d) => d.user)) ||
                    (!isEmpty(props.task.teamMembers) &&
                      props.task.teamMembers?.map((d: any) => d.user)) ||
                    []
                  }
                />
              </Col>
            </Row>
            <Row justify={"start"} align={"top"} className="mt-10">
              <Col span={24}>
                <AlectifyDescription
                  detail={props.task?.detail || ""}
                  height={DescriptionViewHeight.small}
                />
              </Col>
            </Row>
          </>
        )) || <AlectifyEmptyState />}
      </div>
    </Spin>
  );
};

export default WorkOrderBasicInfo;
