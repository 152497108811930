import { Col, Row } from "antd";
import AlectifyMaximizeIcon from "components/icons/AlectifyMaximizeIcon";
import AlectifyButton from "components/shared/button";
import { IAlectifyDescriptionViewProps } from "./AlectifyDescriptionView.interface";
import "./AlectifyDescriptionView.scss";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import AlectifyModal from "../modal";
import { MODAL_NAMES } from "constants/modal.constants";

const AlectifyDescription: React.FC<IAlectifyDescriptionViewProps> = (
  props: IAlectifyDescriptionViewProps,
) => {
  const openDescriptionModal = () => {
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.ALECTIFY_DESCRIPTION_MODAL,
      title: "Description",
      width: 800,
      onCancel: () =>
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.ALECTIFY_DESCRIPTION_MODAL,
        }),
      footer: null,
      children: (
        <div
          className="alectify-description-modal-body"
          dangerouslySetInnerHTML={
            (props.detail && { __html: props.detail }) || { __html: "</>" }
          }
        />
      ),
    });
  };
  return (
    <div>
      <Row justify={"space-between"} align={"middle"} className="mb-5">
        <Col span={12} className="text-align-left">
          <span className="alectify-description-heading">Description</span>
        </Col>
        <Col
          span={12}
          className="alectify-description-maximize-btn-container text-align-right"
        >
          {/* <AlectifyButton
            text="Maximize"
            type="primary"
            className="light-blue-button"
            icon={<AlectifyMaximizeIcon />}
            size="small"
            onClick={openDescriptionModal}
          /> */}
        </Col>
      </Row>
      <Row justify={"center"} align={"middle"}>
        <Col span={24}>
          <div
            className="alectify-description-view-body"
            style={{
              height: props.height,
              maxHeight: "850px",
            }}
            dangerouslySetInnerHTML={
              (props.detail && { __html: props.detail }) || { __html: "</>" }
            }
          />
        </Col>
      </Row>
    </div>
  );
};

export default AlectifyDescription;
