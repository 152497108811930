//libs
import { useEffect, useState } from "react";
import {
  Avatar,
  Col,
  Divider,
  Row,
  Space,
  Spin,
  Typography,
  message,
} from "antd";
import Dayjs from "dayjs";
import { useDispatch } from "react-redux";

//components
import {
  AlectifyDetailCard,
  AlectifyDetailCardImage,
  AlectifyDetailCardTitle,
} from "components/shared/detail-card";
import CheckIcon from "components/icons/CheckIcon";
import CrossIcon from "components/icons/CrossIcon";
import AlectifyButton from "components/shared/button";
import AlectifyModal from "components/shared/modal";
import ConfirmationModal from "components/shared//confirmation/Confirmation";
import { WarningIcon } from "components/icons";
import { getComments } from "redux/components/common/sources";
import AlectifyTable from "components/shared//table";
import { NO_IMAGE_AVATAR } from "components/shared/assets-table/AssetsTable.constants";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import ProcedureCheckListCard from "./ProcedureCheckListCard";
import ViewImage from "components/shared/view-image/ViewImage";

//interfaces
import { IProcedureDetailProps } from "components/shared/procedure-detail/Procedure.interface";

//helpers
import {
  countCheckedSteps,
  getProceduresTag,
} from "pages/procedures/Procedures.helpers";

//constants
import AlectifyText from "static/texts.json";
import { MODAL_NAMES } from "constants/modal.constants";
import { MESSAGES } from "constants/messages";

//services
import {
  IProcedure,
  IProcedureCheckList,
} from "services/procedures/procedures.interface";
import {
  fetchProcedure,
  updatePMSteps,
} from "services/procedures/procedures.service";
import { downloadSingleFileFromURL } from "services/documents/documents.service";

//styles
import "./ProcedureDetail.scss";
import { useParams } from "react-router-dom";
import { truncate } from "lodash";
import { displayDateTime, getFullUserName } from "utils/helpers";

const ProcedureDetail: React.FC<IProcedureDetailProps> = (
  props: IProcedureDetailProps,
) => {
  const dispatch = useDispatch();
  const { pmId } = useParams();
  const TAG = getProceduresTag(props.procedure.jobType);
  const [loader, setLoader] = useState<boolean>(false);
  const [procedure, setProcedure] = useState<IProcedure>();

  const onUpdateSteps = async (
    id: string,
    status: boolean,
    duration?: string,
    comments?: string,
    file?: File | null,
  ) => {
    setLoader(true);

    try {
      const formData = new FormData();
      formData.append("stepId", id);
      formData.append("status", status as any);
      formData.append("comments", comments || "");
      formData.append("durationMins", duration || "");
      formData.append("date", Dayjs().format());
      if (file !== undefined) {
        formData.append("image", file as any);
      }

      const response = await updatePMSteps(props.taskId || "", formData);
      getProcedure(response.data.procedure.id);
      setLoader(false);
      if (response.message) {
        message.success(response.message);
        if (pmId) {
          props?.getPmExternalDetails && props?.getPmExternalDetails(pmId);
        }
        if (props?.subProjectId) {
          dispatch(getComments(props?.subProjectId, props?.referenceId));
        }
      }
    } catch (ex) {
      setLoader(false);
      message.error(MESSAGES.PROCEDURE.STATUS_UPDATE_FAILURE);
    }
  };

  const getProcedure = async (id: string) => {
    setLoader(true);
    try {
      const response = await fetchProcedure(id);
      if (response.data) {
        setProcedure(response.data);
      }
      setLoader(false);
    } catch (ex) {
      setLoader(false);
    }
  };

  const onRemoveConfirmation = () => {
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.PROCEDURE_REMOVE_CONFIRMATION_MODAL,
      title: AlectifyText.CONFIRMATION,
      footer: false,
      closeIcon: null,
      children: (
        <ConfirmationModal
          message={MESSAGES.PROCEDURE.REMOVE_WARNING}
          note={MESSAGES.PROCEDURE.REMOVE_NOTE}
          icon={WarningIcon}
          onCancel={() =>
            ModalServiceInstance.close(AlectifyModal, {
              name: MODAL_NAMES.PROCEDURE_REMOVE_CONFIRMATION_MODAL,
            })
          }
          onConfirm={() => {
            props.onRemove && props.onRemove(null);
            ModalServiceInstance.close(AlectifyModal, {
              name: MODAL_NAMES.PROCEDURE_REMOVE_CONFIRMATION_MODAL,
            });
          }}
        />
      ),
    });
  };

  const downloadFile = async (url: string) => {
    try {
      setLoader(true);
      await downloadSingleFileFromURL(url);
      setLoader(false);
    } catch (ex) {
      setLoader(false);
      console.log(ex);
    }
  };

  useEffect(() => {
    if (!props.readonly) {
      getProcedure(props.procedure.id);
    } else {
      setProcedure(props.procedure);
    }
  }, [props.procedure]);

  return (
    <div className="alectify-procedure-detail-drawer-container">
      <Spin spinning={loader}>
        <AlectifyDetailCard>
          <>
            <Row justify={"start"}>
              <Col span={24}>
                <Typography.Title level={4}>Procedure Details</Typography.Title>
              </Col>
            </Row>
            <Row justify={"space-between"} align={"top"}>
              <Col span={props.showRemoveButton ? 12 : 24}>
                <div className="alectify-procedure-detail-drawer-content mb-10">
                  <AlectifyDetailCardImage
                    src={props?.procedure?.imageUrl || NO_IMAGE_AVATAR}
                    showImage={!!props?.procedure?.imageUrl}
                  />
                  <AlectifyDetailCardTitle
                    title={truncate(procedure?.name, {
                      length: 100,
                      omission: "...",
                    })}
                    extra={
                      <Space
                        size={1}
                        direction="horizontal"
                        className="alectify-procedure-detail-drawer-card-extra"
                      >
                        <span className="alectify-procedure-detail-drawer-card-text-head">
                          Ref #:
                        </span>
                        <strong>{procedure?.reference}</strong>
                      </Space>
                    }
                  />
                </div>
              </Col>
              <Col span={12} className="text-align-right">
                {props.showRemoveButton && (
                  <AlectifyButton
                    name="remove-imported"
                    text="Remove Procedure"
                    type="primary"
                    onClick={onRemoveConfirmation}
                    danger
                  />
                )}
              </Col>
            </Row>
            <Typography.Paragraph className="alectify-procedure-detail-drawer-description-text">
              {procedure?.description}
            </Typography.Paragraph>
            <TAG />
            {procedure?.createdBy ? (
              <div className="procedure-detail-creator-info mt-10">
                <Typography.Paragraph>
                  Created by: {getFullUserName(procedure?.createdBy)}
                </Typography.Paragraph>
                <Typography.Paragraph>
                  Created at: {displayDateTime(procedure?.createdAt)}
                </Typography.Paragraph>
              </div>
            ) : (
              <></>
            )}
            <Divider className="mb-5 mt-0" />
            <Row justify={"space-between"} align={"middle"}>
              <Col span={10}>
                <Typography.Paragraph className="alectify-procedure-detail-drawer-checklist-heading m-0">
                  {`${AlectifyText.CHECK_LIST} ${
                    props.readonly
                      ? `(${procedure?.procedureSteps.length})`
                      : `(${
                          procedure &&
                          countCheckedSteps(procedure.procedureSteps)
                        })`
                  }`}
                </Typography.Paragraph>
              </Col>
              <Col span={14} className="text-align-right">
                <Space direction="horizontal" size={20}>
                  <span className="alectify-procedure-steps-validation-icons">
                    {AlectifyText.COMMENTS_REQUIRED}:{" "}
                    {procedure?.comments ? <CheckIcon /> : <CrossIcon />}
                  </span>
                  <span className="alectify-procedure-steps-validation-icons">
                    {AlectifyText.FILE_ATTACHMENTS}:{" "}
                    {procedure?.fileUpload ? <CheckIcon /> : <CrossIcon />}
                  </span>
                </Space>
              </Col>
            </Row>
            <Row justify={"start"} align={"middle"}>
              <Col span={24}>
                {props.readonly ? (
                  <AlectifyTable
                    bordered
                    columns={[
                      { title: "SNo  #", dataIndex: "order", width: 80 },
                      {
                        title: AlectifyText.DESCRIPTION,
                        dataIndex: "name",
                        render: (_, record: IProcedureCheckList) => {
                          return (
                            <>
                              <Row justify={"space-between"}>
                                <Col
                                  span={22}
                                  className="text-align-justify text-white-space-normal"
                                >
                                  {record.name}
                                </Col>
                                <Col span={2} className="text-align-right">
                                  {(record.imageUrl ||
                                    record.defaultImageUrl) && (
                                    <Avatar
                                      className="cursor-pointer"
                                      src={
                                        record.imageUrl ||
                                        record.defaultImageUrl
                                      }
                                      onClick={() => {
                                        ModalServiceInstance.open(
                                          AlectifyModal,
                                          {
                                            name: MODAL_NAMES.VIEW_IMAGE_MODAL,
                                            title: AlectifyText.VIEW_IMAGE,
                                            children: (
                                              <ViewImage
                                                imageUrl={
                                                  record.imageUrl ||
                                                  record.defaultImageUrl ||
                                                  ""
                                                }
                                              />
                                            ),
                                            onCancel: () => {
                                              ModalServiceInstance.close(
                                                AlectifyModal,
                                                {
                                                  name: MODAL_NAMES.VIEW_IMAGE_MODAL,
                                                },
                                              );
                                            },
                                            footer: null,
                                          },
                                        );
                                      }}
                                    />
                                  )}
                                </Col>
                              </Row>
                            </>
                          );
                        },
                      },
                    ]}
                    dataSource={procedure?.procedureSteps || []}
                    size="small"
                    total={0}
                    showPagination={false}
                    onDataFetch={() => {}}
                    scroll={{ y: "calc(100vh - 400px)" }}
                  />
                ) : (
                  <ProcedureCheckListCard
                    procedureSteps={procedure?.procedureSteps || []}
                    onStatusUpdate={onUpdateSteps}
                    procedure={procedure as any}
                    downloadFile={downloadFile}
                    isDisabled={props.isDisabled}
                  />
                )}
              </Col>
            </Row>
          </>
        </AlectifyDetailCard>
      </Spin>
    </div>
  );
};

export default ProcedureDetail;
